@import "../config";
body.page-sobre {
  .big-image,
  .big-image img {
    width: 100vw;
    position: relative;
    height: 440px;
    @media all and(max-width: 500px) {
      object-fit: cover;
      object-position: 20%;
    }
  }
  .sobre-content {
    background-color: white;
    font-size: 20px;
    padding-top: 100px;
    background-image: url('../../assets/images/pabloiachetti_triangulos.png');
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 35%;
    @media all and(max-width: 500px) {
      padding-top: 50px;
    }
    .parrafo {
      margin: auto;
      width: 60%;
      font-family: TitilliumWeb-Light;
      padding-bottom: 60px;
      @media all and(max-width: 500px) {
        width: 80%;
      }
      .negrita {
        font-family: TitilliumWeb-Bold;
        padding-bottom: 60px;
        @media all and(max-width: 500px) {}
      }
    }
    .parrafo-cita {
      font-family: 'Times New Roman', Times, serif;
      font-style: oblique;
      border-left: 5px solid black;
      padding-left: 60px;
      margin: auto;
      width: 60%;
      margin-bottom: 60px;
      @media all and(max-width: 500px) {
        padding-left: 20px;
      }
    }
  }
  .text-quote {
    position: absolute;
    top: 250px;
    right: 280px;
    color: white;
    font-family: 'Times New Roman', Times, serif;
    overflow-wrap: break-word;
    font-style: italic;
    width: 380px;
    font-size: 22px;
    letter-spacing: 1px;
    @media all and(max-width: 500px) {
      // overflow-wrap: none;
      top:50px;
      width: 80%;
      right: 0;
    }
  }
  .author {
    position: absolute;
    top: 350px;
    right: 300px;
    color: white;
    font-family: Brandon_blk_1;
    @media all and(max-width: 500px) {
      top:250px;
      width: 80%;
      right: 0;
    }
  }
  .sobre-videos {
    .sobre-titulo-videos {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      a{
        text-decoration: none;
        color: #5E1A6B;
        font-family: Brandon_blk_1;
        text-align: center;
        font-size: 28px;
        letter-spacing: 6px;
        cursor: pointer;

      }
    }
    .sobre-tira-videos {
      height: 300px;
      display: flex;
      .sobre-item-video {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          height: 100%;
        }
        iframe {
          width: 99%;
          height: 100%;
        }
        @media all and(max-width:500px) {
          flex-direction: column;
        }
      }
    }
  }
}