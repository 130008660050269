subscribe-newsletter {
    .subscribe-to-newsletter {
        display: flex;
        align-items: center;
        justify-content:center;
        font-size: 15px;
        font-family: Brandon_blk_1;
        background-color: #EDEDED;
        padding:10px 0;
        @media all and (max-width: 992px) {
            flex-direction: column;
            flex-wrap: nowrap;
        }
        span.text-highlight {
            color: #5E1A6B;
            @media all and (max-width: 992px) {
                padding: 0;
            }
        }
        .text {
            margin:0 15px 0 0;

            @media all and (max-width: 992px) {
                padding: 0;
            }
        }
        input[type=email] {
            background-color: rgb(219, 219, 219);
            border: none;
            padding: 3px 8px 3px 8px;
            margin-right: 5px;
            font-family: TitilliumWeb-Light;
            font-size: 20px;
            // height: 34px; //z-index:1000;
        }
        button ,input[type=submit]{
            border: none;
            letter-spacing: 3px;
            font-family: AlternateGotNo1D;
            background-color: #525254;
            color: white;
            padding:3px 15px;
            margin:0 15px;
            
            cursor: pointer;
            @media all and (max-width: 992px) {
            //  margin-top: 5px;
            }
            
        }
        input[type=submit]:disabled{
            background-color: #dddddd;
        }
        & {
            padding-left: 0px;
        }
    }
}