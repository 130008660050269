blog-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .blog-page-content {
    width: 90%;
    position: relative;
    .blog-grid {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      .card-blog-entry {
        height: 420px;
        width: 23%;
        min-width: 250px;
        max-width: 320px;
        margin: 7px;
        background-color: #ccc;
        position: relative;
        overflow: hidden;
       
        .image-entry {
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          width: 100%;
          height: 214px;
          @include ease-transition;

          &:hover{
            width: 99%;
            @include sombra(inset 1px 1px 0 1px #888);
          }
        }
        .blog-card-title {
          font-family: Brandon_blk_1;
          font-size: 22px;
          overflow-wrap: break-word;
          line-height: 26px;
          text-transform: uppercase;
          margin-right: 15px;
          margin-left: 15px;
          margin-top: 5px;
          margin-bottom: 5px;
          text-align: center;
          @include ease-transition();
          &:hover {
            text-decoration: underline;
          }
        }
        .tag-list-row {
          min-height: 30px;
          margin-right: 15px;
          margin-left: 15px;
          margin-top: 5px;
        }
        .tag-list-row a,
        .tag-list-row a:link,
        .tag-list-row a:visited {
          margin-top: 10px;
          color: #5E1A6B;
          font-family: AlternateGotNo1D;
          font-size: 20px;
          text-transform: uppercase;
          cursor: pointer;
          @include ease-transition();
          &:hover {
            text-decoration: underline;
          }
        }
        .blog-short-text {
          margin-right: 15px;
          margin-left: 15px;
          margin-bottom: 10px;
          span{
            a.categoria{
              font-size: 20px ;
              font-family: TitilliumWeb-Light;
              font-weight: normal;  
              @include ease-transition;
              &:hover{
                color:#fff;
              }
            }

          }
        }
        .footer-card {
          color: white;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-family: TitilliumWeb-light;
          font-size: 12px;
          position: absolute;
          bottom: 0px;
          background: url('../assets/images/pabloiachetti_placadegradado.jpg') no-repeat center center;
          background-size: 100% 100%;
          padding: 4px;
          width: 100%;
          .read-more {
            margin-left: 10px;
            @include ease-transition();
            &:hover {
              @include sombra(1px 1px 0 #ccc);
            }
            a {
              color: white;
            }
          }
          i.fa.fa-play-circle {
            margin-left: 6px;
            margin-right: 6px;
          }
          .separado {
            border-left: white solid 1px;
            height: 23px;
          }
          i.fa.fa-eye {
            margin-left: auto;
            @include ease-transition();
            &:hover {
              @include sombra(0 0 4px #ccc);
            }
          }
          .comment-icon {
            margin-left: 12px;
            @include ease-transition();
            &:hover {
              @include sombra(0 0 4px #ccc);
            }
            a {
              color: white;
            }
          }
          .share-icon {
            @include ease-transition();
            &:hover {
              @include sombra(0 0 4px #ccc);
            }
            a {
              color: white;
            }
            margin-left: 12px;
            margin-right: 12px;
          }
        } // }
      }
      .card-blog-entry:hover {
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
  }
  .paginado {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    .flechas {
      width: 30%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .flecha {
        @include minmaxwh(30px);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #5E1A6B;
        color: #fff;
        border-radius: 50%;
        @include ease-transition;
        cursor: pointer;
        &:hover {
          color: #5E1A6B;
          background-color: #fff;
          @include sombra(0 2px 0 0 #aaa);
        }
      }
    }
  }
}