home-cabecera{.big-image,
.big-image img {
  width: 100%;
  position: relative;
  height: 500px;
  @media all and(max-width:500px){
    object-fit: cover;
  }
}
.text-main {
  position: relative;
  top: -70%;
  width: 100%;
  text-align: center; //z-index: 1;
  color: white;
  font-size: 9vw;
  font-family: ArcaMajora3-Heavy;
  letter-spacing: 20px;
  @media all and(max-width: 660px) {
    letter-spacing: 15px;

  }
}
button.call-to-action {
  cursor: pointer;
  position: absolute;
  background-color: #5E1A6B;
  font-family: AlternateGotNo1D;
  font-size: 30px;
  top: 70%;
  left: 40vw;
  height: 50px;
  width: 18vw;
  border: none;
  color: white;
  letter-spacing: 6px;
  padding-bottom: 7px;
  @media all and(max-width: 660px) {
    left: 5%;
    width: 90%;
  }
}
}
