newsletters {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;

  @media all and (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }

  .titulo {
    text-transform: uppercase;
    font-size: 150%;
    @media all and (max-width: $btLG) {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 120%;
      margin-bottom: 15px;
    }
  }
  .formulario {
    @media all and (max-width: $btLG) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
    }

    form {
      margin: auto;
      width: 90%;
      display: flex;
      @media all and (max-width: $btLG) {
        display: block;
      }
      * {
        margin: 0 15px;
        @media all and (max-width: $btLG) {
          margin: auto;
        }
      }
      .email {
        border: 1px solid #ccc;
        padding: 5px 10px;
        border-radius: 2px;
        min-width: 350px;
        color: #333;
        @media all and (max-width: $btLG) {
          min-width: inherit;
          width: 100%;
        }
      }
      .subm {
        border-radius: 0;
        border: 0;
        background-color: #333;
        color: #fff;
        text-transform: uppercase;
        padding: 6px 15px;
        opacity: 1;
        @include ease-transition(250ms);
        @media all and (max-width: $btLG) {
          display: block;
          margin: auto;
          margin-top: 15px;
          &:not([disabled="disabled"]) {
            @include sombra(0 0 3px 0 #111);
          }
        }

        &:not([disabled="disabled"]):hover {
          @include sombra(0 0 3px 0 #111);
        }

        &[disabled="disabled"] {
          opacity: 0.3;
        }
      }
    }
  }
  .respuesta {
    margin-left: 20px;
    color: #ccc;
    font-style: italic;
  }
}
