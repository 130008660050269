@import "config";
@import "fonts";
@import "animaciones";
.ng-cloak,
.x-ng-cloak,
[data-ng-cloak],
[ng-cloak],
[ng\:cloak],
[x-ng-cloak] {
  display: none !important;
}

* {
  box-sizing: border-box;
  max-width: 100%;
}

button,
input[type=submit],
a[role=button] {
  border-radius: 5px;
}
a:focus,button:focus {
  outline: none;
}


/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: #333; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */

/* Add animation: Take 0.5 seconds to fade in and out the snackbar.
However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadeinSnackbar 0.5s, fadeoutSnackbar 0.5s 2.5s;
  animation: fadeinSnackbar 0.5s, fadeoutSnackbar 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadeinSnackbar {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadeinSnackbar {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeoutSnackbar {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeoutSnackbar {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  text-align: center;
}

input[type="number"] {
  -moz-appearance: textfield;
  margin: 0;
  text-align: center;
}

input,
select {
  &.ng-dirty.ng-valid {
    @include sombra(0 0 1px 0 #1f1);
  }
  &.ng-dirty.ng-invalid,
  &.ng-dirty.ngInvalid {
    @include sombra(0 0 1px 0 #f11);
  }
  &.required::before {
    content: "*";
    display: block;
    float: right;
    color: "#666";
    margin-left: 2px;
    color: #f33;
  }
}

body {
  background-color: #fff;
  min-width: $mqMobileS;
  font-size: 14px;
}

@import "general/index";
section.contenido {
  position: relative;

}

// breakpoints
@media all and(min-width:1300px){}
