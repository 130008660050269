@font-face {
  font-family: "AlternateGotNo1D";
  src: url("../../assets/fonts/AlternateGotNo1D.ttf");
}
@font-face {
  font-family: "ArcaMajora3-Bold";
  src: url("../../assets/fonts/ArcaMajora3-Bold.otf"); 
}
@font-face {
  font-family: "ArcaMajora3-Heavy";
  src: url("../../assets/fonts/ArcaMajora3-Heavy.otf"); 
}
@font-face {
  font-family: "Brandon_blk_1";
  src: url("../../assets/fonts/Brandon_blk_1.otf"); 
}
@font-face {
  font-family: "Brandon_med_1";
  src: url("../../assets/fonts/Brandon_med_1.otf"); 
}
@font-face {
  font-family: "FontAwesome_0";
  src: url("../../assets/fonts/FontAwesome_0.otf"); 
}
@font-face {
  font-family: "HelveticaNeueLTStd-BdCn_1";
  src: url("../../assets/fonts/HelveticaNeueLTStd-BdCn_1.otf"); 
}
@font-face {
  font-family: "HelveticaNeueLTStd-Cn_2";
  src: url("../../assets/fonts/HelveticaNeueLTStd-BdCn_1.otf"); 
}
@font-face {
  font-family: "HelveticaNeueLTStd-LtCn_1";
  src: url("../../assets/fonts/HelveticaNeueLTStd-LtCn_1.otf"); 
}
@font-face {
  font-family: "HelveticaNeueLTStd-Md";
  src: url("../../assets/fonts/HelveticaNeueLTStd-Md.otf"); 
}
@font-face {
  font-family: "HelveticaNeueLTStd-Roman";
  src: url("../../assets/fonts/HelveticaNeueLTStd-Roman.otf"); 
}
@font-face {
  font-family: "timesi";
  src: url("../../assets/fonts/timesi.ttf"); 
}
@font-face {
  font-family: "TitilliumWeb-Bold";
  src: url("../../assets/fonts/TitilliumWeb-Bold_0.ttf"); 
}
@font-face {
  font-family: "TitilliumWeb-Light";
  src: url("../../assets/fonts/TitilliumWeb-Light.ttf"); 
}
@font-face {
  font-family: "Roboto-Regular";
  src:url("../../assets/fonts/Roboto/Roboto-Regular.ttf");
}
