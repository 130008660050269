@import "../config";
.eventos-big-image,
.eventos-big-image img {
  width: 100vw;
  position: relative;
  height: 440px;
  color: white;
  @media all and(max-width:500px) {
    object-fit: cover;
  }
  .eventos-titulo-sobre-imagen {
    position: absolute;
    font-family: ArcaMajora3-Heavy;
    font-size: 120px;
    top: 150px;
    letter-spacing: 20px;
    width: 100%;
    text-align: center;
    @media all and(max-width:500px) {
      font-size: 40px;
    }
  }
  .eventos-detalle-titulo {
    position: relative;
    letter-spacing: 1.5px;
    left: -380px;
    top: -110px;
    transform: rotate(270deg);
    font-size: 12px;
    font-family: ArcaMajora3-Bold;
  }
}

.eventos-content {
  font-family: TitilliumWeb-Light;
  background-color: white;
  padding-bottom: 200px;
  .navegacion-eventos {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
    .button-evento-anterior {
      .evento-anterior {
        background-color: grey;
        color: white;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border: 0px;
        margin-right: 1px;
        font-family: TitilliumWeb-Light;
        cursor: pointer;
      }
      .evento-anterior-fa {
        font-size: 16px;
        background-color: grey;
        color: white;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: 20px;
        border: 0px;
        margin-right: 2px;
        padding-right: 1px;
        padding-bottom: 1px;
      }
    }
    .button-evento-anterior:hover {
      .evento-anterior {
        background-color: #B1A1B4;
        color: #5E1A6B;
      }
      .evento-anterior-fa {
        background-color: #B1A1B4;
        color: #5E1A6B;
      }
    }
    .button-evento-siguiente {
      .evento-siguiente {
        background-color: grey;
        color: white;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 0px;
        margin-left: 1px;
        font-family: TitilliumWeb-Light;
        cursor: pointer;
      }
      .evento-siguiente-fa {
        font-size: 16px;
        background-color: grey;
        color: white;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: 20px;
        border: 0px;
        margin-left: 2px;
        padding-left: 1px;
        padding-bottom: 1px;
      }
    }
    .button-evento-siguiente:hover {
      .evento-siguiente {
        background-color: #B1A1B4;
        color: #5E1A6B;
      }
      .evento-siguiente-fa {
        background-color: #B1A1B4;
        color: #5E1A6B;
      }
    }
  }
  .lista-eventos {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top:50px;
    max-height: 900px;
    overflow-y: auto;
    .evento {
      display: flex;
      height: 400px;
      @media all and(max-width:500px) {
        flex-direction: column;
        height: auto;
      }
      .lugar {
        width: 35%;
        @media all and(max-width:500px) {
          width: auto;
        }
        .linea-separadora-lugar {
          border-top: #5E1A6B 6px solid;
          .evento-lugar {
            img {
              position: relative;
              float: right;
              top: 50px;
              margin-right: 40px;
              height: 80px;
              widows: 80px;
            }
          }
        }
      }
      .descripcion {
        width: 70%;
        padding-top: 5px;

        @media all and(max-width:500px){
          width: auto;
        }
        .linea-separadora-descripcion {
          border-top: #5E1A6B 1px solid;
          .evento-descripcion {
            margin-top: 50px;
            .titulo {
              font-family: Brandon_blk_1;
              font-size: 30px;
              text-transform: uppercase;
            }
            .subtitulo {
              color: #5E1A6B;
              font-family: TitilliumWeb-Light;
              font-style: oblique;
              font-size: 24px;
            }
            .descripcion {
              font-size: 20px;
            }
            .button-mas-info {
              margin-top: 30px;
              @media all and(max-width:500px){
                margin:30px;
              }
              button.mas-info {
                background-color: #B1A1B4;
                color: #5E1A6B;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border: 0px;
                width: 100px;
                margin-left: 1px;
                font-family: TitilliumWeb-Bold;
                cursor: pointer;
                @media all and(max-width:500px){
                }
              }
              button.mas-info-fa {
                background-color: #B1A1B4;
                color: #5E1A6B;
                font-size: 16px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                width: 20px;
                border: 0px;
                margin-left: 2px;
                padding-left: 1px;
                padding-bottom: 1px;
              }
            }
            .button-mas-info:hover {
              button.mas-info {
                background-color: #5E1A6B;
                color: #B1A1B4;
                cursor: pointer;
              }
              button.mas-info-fa {
                background-color: #5E1A6B;
                color: #B1A1B4;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

body[class^="page-eventos-"] {
  .show-evento {
    padding-bottom: 200px;
  }
  .evento {
    margin-top: 100px;
    display: flex;
    @media all and(max-width:500px) {
      flex-direction: column;
    }
  }
  .lugar {
    width: 35%;
    @media all and(max-width:500px) {
      width: auto;
    }
    .linea-separadora-lugar {
      border-top: #5E1A6B 6px solid;
      .evento-lugar {
        img {
          position: relative;
          top: 50px;
          float: right;
          height: 80px;
          widows: 80px;
          margin-right: 40px;
        }
      }
    }
  }
  .descripcion {
    width: 70%;
    padding-top: 5px;
    @media all and(max-width:500px) {
      width: auto;
    }
    .linea-separadora-descripcion {
      border-top: #5E1A6B 1px solid;
      .evento-descripcion {
        margin-top: 50px;
        .titulo {
          font-family: Brandon_blk_1;
          font-size: 40px;
          text-transform: uppercase;
          margin-bottom: 30px;
          @media all and(max-width:500px) {
              text-align: center;
          }
          
        }
        .subtitulo {
          color: #5E1A6B;
          font-family: TitilliumWeb-Light;
          font-style: oblique;
          font-size: 24px;
          margin-bottom: 30px;
          @media all and(max-width:500px){
            width: 90%;
            margin:auto;
          }
        }
        .descripcion {
          position: relative;
          font-size: 21px;
          font-family: TitilliumWeb-Light;
          color: #181818;
          margin-bottom: 28px;
          @media all and(max-width:500px){
            width: 90%;
            margin:auto;
            margin-bottom: 58px;
          }
        }
        .evento-info-lugar,
        .evento-info-fecha {
          font-size: 21px;
          font-family: TitilliumWeb-Light;
          color: #181818;
          margin-bottom: 28px;
        }
        .evento-info {
          left: 20px;
          width: 500px;
          font-size: 21px;
          font-family: TitilliumWeb-Light;
          color: #181818;
        }
        .botones {
          width: 50%;
          display: flex;
          justify-content: space-between;
          flex-wrap: nowrap;

          @media all and(max-width:500px) {
            justify-content: flex-start;
           flex-direction: column;
          }
        }
        .button-inscribirse {
          button.inscribirse {
            cursor: pointer;
            background-color: #B1A1B4;
            color: #5E1A6B;
            border-radius: 10px;
            border-radius: 10px;
            border: 0px;
            padding: 5px 10px;
            margin-left: 1px;
            font-family: TitilliumWeb-Bold;
            &:hover {
              background-color: #5E1A6B;
              color: #B1A1B4;
            }
            @media all and(max-width:500px) {
             margin:5px;
             width:auto;
              height: auto;
            }
          }
        }
        button.volver-a-eventos {
          padding: 5px 10px;
          background-color: #ccc;
          color: #222;
          border-radius: 10px;
          border-radius: 10px;
          border: 0px;
          margin-left: 1px;
          font-family: TitilliumWeb-Bold;
          cursor: pointer;
          @media all and(max-width:500px) {
            margin:5px;
            width:auto;
            height: auto;
           }
        }
        button.volver-a-eventos:hover {
          background-color: #222;
          color: #ccc;
        }
      }
    }
  }
}

li.item-lugar:before {
  content: "\f041";
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -1.3em;
  width: 1.3em;
}

li.item-fecha:before {
  content: "\f133";
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -1.3em;
  width: 1.3em;
}

.popup-inscripcion {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vw;
  background-color: rgba(100, 100, 100, 0.8);
  z-index: 100;
  justify-content: center;
  align-items: center;
  .popup-formulario {
    position: relative;
    margin: 0 auto;
    width: 60%;
    padding: 100px 100px;
    background-color: white;
    top: 10%;
    -webkit-box-shadow: 2px 2px 1px 2px #5E1A6B;
    box-shadow: 2px 2px 1px 2px #5E1A6B;
    .a {
      color: #5E1A6B;
      text-transform: uppercase;
      margin-bottom: 15px;
      font-family: Brandon_blk_1;
    }
    input[type=text],input[type=email] {
      font-family: Brandon_med_1;
      width: 100%;
      margin: auto;
      margin-bottom: 15px;
      padding:4px;
      border:none;
      border-bottom:1px solid #ccc;
    }
    i {
      font-family: Brandon_med_1;
      width: auto;
      margin-left: 50%;
      margin-bottom: 15px;
    }
    .botones{
      display: flex;
      justify-content: center;
    }
    input[type=submit] {
      font-family: Brandon_med_1;
      background-color: #B1A1B4;
      color: #5E1A6B;
      border-radius: 10px;
      border: 0px;
      margin: 5px;
      padding: 3px 10px;
      font-family: TitilliumWeb-Bold;
      margin-bottom: 15px;
      cursor: pointer;
      &:disabled{
        background-color: #ccc;
        color:#ddd;
      }
      &:hover{
        background-color: #c3b0c7;
      }
    }
    button.cancelar {
      &:hover{
        background-color: #802292;
      }
      color: #B1A1B4;
      background-color: #5E1A6B;
      border-radius: 10px;
      border: 0px;
      margin-top: 5px;
      font-family: TitilliumWeb-Bold;
      margin-bottom: 15px;
      padding: 3px 10px;
      cursor: pointer;
    }
  }
}

body>div>section>div>div>div.eventos-content>div.lista-eventos>div:nth-child(3) {
  height: 0px;
  @media all and(max-width:500px) {
   height: auto; 
  }
}