footer-section {
  position: relative;
  left: 0px;
  width: 100%;
  overflow-x: hidden;
  background-color: #525254;
  padding: 60px 0;
  * {
    box-sizing: border-box;
  }
  display: flex;
  justify-content: space-evenly;
  @media all and(max-width:992px) {
    flex-direction: column;
  }
  .separador-vertical {
    height: auto;
    background-color: white;
    width: 2px;
  }
  .branding {
    margin: auto 0;
    color: white;
    .logo {
      font-family: ArcaMajora3-Bold;
      text-align: center;
      color: white;
      position: relative;
      letter-spacing: 3px;
      font-size: 30px;
    }
    .social {
      position: relative;
      color: white;
      margin: 20px 0;
      .items {
        display: flex;
        justify-content: space-evenly;
        .icono {
          a {
            color: white;

            &:hover{
              i{
              @include escalar(1.3);
            }
            }
            i {
              @include ease-transition();
              color: white;
              cursor: pointer;
            }
          }
        }
      }
    }
    .copyright-info {
      font-size: 10px;
      @media all and(max-width: 992px) {
        text-align: center;
      }
    }
  }
  .comunication {
    width: 550px;
    margin: auto 0;
    @media all and(max-width: 992px) {
      margin: auto;
    }
    .contacto {
      form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        margin: 0 10px;
        @media all and(max-width: 500px) {
          margin:0 2%;
        }
        .fila {
          margin: 10px 0;
          display: flex;
          @media all and(max-width: 500px) {
            flex-direction: column;
            margin: 0;
          }
          input[type=email],
          input[type=text] {
            width: 49%;
            height: 36px;
            color: #525254;
            background-color: white;
            border-style: none;
            border: 0.5px solid rgb(133, 133, 133);
            padding-left: 10px;
            @media all and(max-width: 500px) {
              width: 95%;
            }
          }
          input[type=email]::-webkit-input-placeholder,
          input[type=text]::-webkit-input-placeholder {
            color: #525254;
            font-family: TitilliumWeb-Light;
            font-size: 16px;
          }
          .footer-contacto-email {
            margin-left: 1%;
            @media all and(max-width: 500px) {
              margin:0
            }
          }
          .footer-contacto-nombre {
            margin-right: 1%;
            @media all and(max-width: 500px) {
              margin:0
            }
          }
        }
        textarea {
          color: #525254;
          background-color: white;
          border-style: none;
          border: 0.5px solid rgb(133, 133, 133);
          padding: 10px;
          resize: none;
          @media all and(max-width: 500px) {
            width: 95%;
          }
        }
        textarea::-webkit-input-placeholder {
          color: #525254;
          font-family: TitilliumWeb-Light;
          font-size: 16px;
        }
        .submit {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          margin: 10px 0;
          @media all and(max-width:500px) {
            align-items: flex-start;
            justify-content: flex-start;
          }

        }
        input[type=submit] {
          background-color: #5E1A6B;
          color: white;
          border-style: none;
          font-family: TitilliumWeb-Bold;
          font-size: 18px;
          width: 10vw;
          height: 36px;
          letter-spacing: 0.2vw;
          &:disabled {
            background-color: #ddd;
          }
          @media all and(max-width:500px) {
            font-size: 15px;
            margin: 0;
            width: auto;
            height: auto;
          }
        }
      }
    }
  }
  .newsfeed {
    .titulo-noticias {
      color: white;
      border-bottom: #5E1A6B 2px solid;
    }
    .feed {
      .asd {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin: 0 10px;
      }
      .evento-feed {
        padding: 4px 0;
        margin: 30px 0;
        display: flex;
        border-bottom: solid 1px #666;
        .evento-feed-titulo {
          a {
            color: white;
          }
          cursor: pointer;
          color:white;
          font-family: TitilliumWeb-Bold;
        }
        .evento-feed-texto {
          color: white;
          font-family: TitilliumWeb-Light;
        }
        .evento-feed-imagen {
          img {
            height: 50px; // width: 70px;
          }
        }
      }
      .noticia-feed {
        padding: 4px 0;
        margin: 30px 0;
        display: flex;
        border-bottom: solid 1px #666;
        .noticia-feed-titulo {
          cursor: pointer;
          color: white;
          font-family: TitilliumWeb-Bold;
        }
        .noticia-feed-texto {
          a {
            color: white;
          }
          color:white;
          font-family: TitilliumWeb-Light;
        }
        .noticia-feed-imagen {
          img {
            height: 70px;
            width: 70px;
          }
        }
      }
    }
  }
  .logo-items {
    border-top: solid 5px #380F40;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 268px;
    background-color: #525254;
    @media all and (max-width: $btMD) {
      height: auto;
      flex-direction: column;
    }
    .footer-first-row {
      color: white;
      position: relative;
      top: 44px;
      left: 6.3vw;
      letter-spacing: 0.2vw;
      font-size: 1.3vw;
      .logo {
        width: auto;
        font-family: ArcaMajora3-Heavy;
      }
    }
    .footer-copyright {
      background-color: #2C2C2F;
      text-align: center;
      .copyright-info {
        font-family: TitilliumWeb-Light;
        color: white;
      }
    }
    .footer-second-row {
      color: white;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      position: relative;
      .label-newsletter {
        font-family: Brandon_med_1;
        font-size: 1vw;
        letter-spacing: 0.2vw;
      }
      input[type=email] {
        height: 36px;
        color: white;
        background-color: #525254;
        border-style: none;
        border: 0.5px solid rgb(133, 133, 133);
        width: 24vw;
        padding-left: 10px;
      }
      input[type=email]::-webkit-input-placeholder {
        color: white;
        font-family: TitilliumWeb-Light;
        font-size: 16px;
      }
      input[type=submit] {
        background-color: #5E1A6B;
        color: white;
        border-style: none;
        font-family: TitilliumWeb-Bold;
        font-size: 1.1vw;
        width: 10vw;
        height: 36px;
        letter-spacing: 0.2vw;
      }
      a.text {
        color: white;
        font-family: ArcaMajora3-Heavy;
        letter-spacing: 10px;
        font-size: 1.3vw;
      }
    }
    .items {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      @media all and (max-width: $btMD) {
        height: auto;
        flex-direction: column;
      }
      .item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 5px;
        .icono {
          min-height: 25px;
          min-width: 25px;
          max-height: 25px;
          max-height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50px;
          margin-right: 10px;
          i {
            font-size: 2vw;
            margin-left: -1px;
            color: #fff;
          }
          i:hover {
            cursor: pointer;
          }
        }
        .texto {
          a {
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }
  }
}
