subscribe-events{
    .eventos-subscribe-to-newsletter{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        padding:15px;
        font-family: Brandon_blk_1;
        background-color: #5E1A6B !important;
        position: relative;
        @media all and(max-width:500px){
          padding:8px;
          flex-direction: column;
        }
        span.eventos-text-highlight {
          color:white;
          // padding-left: 20vw;
        }
        .eventos-text{
          color: rgb(148, 99, 158) !important;
          margin:0 10px;
          // padding-right: 2vw;
        }
        input{
          border:none;
          padding: 3px 8px;
          // padding-left: 1vw;
          // margin-right: 2vw;
          font-family:TitilliumWeb-Light;
          font-size: 15px;
          margin:0 10px;
          @media all and(max-width:500px) {
            margin:10px 5px;
          }
          // width: 23vw;
          // height: 34px;
          //z-index:1000;
        }
        button:hover{
          background-color:rgb(219, 219, 219);
          color:black;
        }
        button{
          padding: 3px 8px;
          background-color: #B1A1B4;
          border:none;
          // width: 8vw;
          letter-spacing:3px;
          font-family: AlternateGotNo1D;
          
          color:white;
          // padding-bottom: 0.4px;
        }
        
      }
}