/// RADAR

.radarLike {
  &::after {
    position: absolute;
    display: block;
    border-radius: 100%;
    content: "";
    border: 5px solid #686c6c;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-animation: radarLike 1.7s ease-in-out alternate;
    animation: radarLike 1.7s ease-in-out alternate;
    -webkit-animation-iteration-count: 6;
    animation-iteration-count: 6;
    transform: translate3d(0, 0, 0);
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@-webkit-keyframes radarLike {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0.6;
  }
}
@keyframes radarLike {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0.6;
  }
}

/// RADAR

/// SCROLL BANNER

.scrollBanner {
  -webkit-animation-name: scrollBanner;
  animation-name: scrollBanner;
  -webkit-animation-duration: 30s;
  animation-duration: 30s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  transform: translate3d(0, 0, 0);
}

@-webkit-keyframes scrollBanner {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  2.38% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  34.13% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  36.51% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
@keyframes scrollBanner {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  2.38% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  34.13% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  36.51% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

/// SCROLL BANNER

/// SLIDE IN DOWN

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  transform: translate3d(0, 0, 0);
}
@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

/// SLIDE IN DOWN

/// SLIDE OUT UP

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    // visibility: hidden;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
@keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    // visibility: hidden;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

/// SLIDE OUT UP

/// SMALL SLIDE IN LEFT

.smallSlideInLeft {
  -webkit-animation-name: smallSlideInLeft;
  animation-name: smallSlideInLeft;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  transform: translate3d(0, 0, 0);
}
@-webkit-keyframes smallSlideInLeft {
  0% {
    -webkit-transform: translateX(-30%);
    transform: translateX(-30%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes smallSlideInLeft {
  0% {
    -webkit-transform: translateX(-30%);
    transform: translateX(-30%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

/// SMALL SLIDE IN LEFT

/// FADE IN

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  transform: translate3d(0, 0, 0);
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/// FADE IN
/// Alargar

.alargar {
  -webkit-animation-name: alargar;
  animation-name: alargar;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  transform: translate3d(0, 0, 0);
}
@-webkit-keyframes alargar {
  0% {
    height: 0;
  }
  100% {
    height: 100px;
  }
}
@keyframes alargar {
  0% {
    height: 0;
  }
  100% {
    height: 100px;
  }
}

/// Alargar

/// FADE OUT

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-duration: 700ms;
  animation-duration: 700ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/// FADE OUT

/// SLIDE IN LEFT

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  transform: translate3d(0, 0, 0);
}
@-webkit-keyframes slideInLeft {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slideInLeft {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

/// SLIDE IN LEFT

/// SLIDE IN RIGHT

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slideInRight {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

/// SLIDE IN RIGHT

/// SLIDE OUT RIGHT

.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    visibility: hidden;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    visibility: hidden;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

/// SLIDE OUT RIGHT

/// SLIDE OUT LEFT

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  transform: translate3d(0, 0, 0);
}
@-webkit-keyframes slideOutLeft {
  100% {
    -webkit-transform: translateX(-300px);
    transform: translateX(-300px);
    visibility: visible;
  }
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes slideOutLeft {
  100% {
    -webkit-transform: translateX(-300px);
    transform: translateX(-300px);
    visibility: visible;
  }
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

/// SLIDE OUT LEFT
