a:link,
a:visited {
  color: black;
  text-decoration: none;
}

@media all and(min-width:992px) {
  .nav {
    top:0;
    z-index: 5;
    position: fixed;
    width: 100vw;
    height: 62px;
    display: flex nowrap !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
    background-color: #000 !important;
    @include ease-transition(300ms);
    &.flotante{
      z-index: -1;
      opacity: 0;
      height: 0;
      
    }
    .item {
      padding-left: 2vw;
      color: #fff;
      list-style: none;
      font-size: 2vw;
      padding-top: 3px;
      a {
        color: white;
        i.fa:hover {
          cursor: pointer;
          color:#5E1A6B;
        }
      }
    }
    .item:last-child {
      padding-right: 1.8vw;
    }
  }
  .menu {
    z-index: 5;
    position: fixed;
    top: 60px;
    width: 100vw;
    height: 96px;
    padding-top: 34px;
    padding-left: 3.6vw;
    padding-bottom: 10px;
    background-color: white;
    display: flex;
    flex-direction: row;
    -webkit-box-shadow: 0 1px 10px 1px #5C5C5C;
    box-shadow: 0 1px 10px 1px #5C5C5C;
    @include ease-transition();
    &.flotante{
      top:0;
      padding: 15px;
      height: auto;
      justify-content: center;
      align-items: center;
      *{
        margin:0;
      }
      a{
        height: auto;
        padding: 4px 10px;
        
      }
      .brand-title{
        margin: 17px 0 0 0;
      }

    }
  }
  .brand-title {
    font-family: ArcaMajora3-Heavy;
    font-size: 1.8vw;
    letter-spacing: .15vw;
    margin-bottom: 20px;
    position: relative;
    top: -8px;
    cursor: pointer;
  }
  .menu-list {
    display: flex;
    flex-direction: row;
    .menu-list-item:first-child {
      padding-left: 8vw;
    }
    .menu-list-item {
      padding-right: 6vw;
      font-family: Brandon_med_1;
      font-size: 1vw;
    }
    .menu-list-item a:hover {
      color: #5E1A6B;
      cursor: pointer;
    }
  }
  .active a {
    color: #5E1A6B;
  }
}

@media all and(max-width:992px) {
  .nav {
    position: fixed;
    z-index: 6;
    width: 100vw;
    top:0;
    left:0;
    background-color: black;
    padding: 14px 0;
    a,
    a:link,
    a:visited {
      padding:5px 20px;
      color: white;
    }
    .hamburguer{
      margin:0 20px 0 auto;
      i{
      color: white;}
    }
  }
  .menu{
    position: fixed;
    z-index: 6;
    height: 260px;
    top:52px;
    left:0;
    padding:10px 0;
    background-color: white;
    width: 100vw;

    a.brand-title{
      padding: 5px 06px;
      border-bottom:1px solid #ccc;
      font-weight: bold;
      position: relative;
      left: 30%;
    }
    .menu-list-item{
      padding: 10px 16px;
      border-bottom:1px solid #ccc;
    }
  }
}