home-blog{
.blog-showcase {
  * {
    cursor: pointer;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  background-color:#EDEDED;
  position: relative;
  padding-top: 75px;
  padding-bottom: 200px;
  margin: auto;
  .blog-title-main {
    width: 86vw;
    margin: auto;
    margin-bottom: 30px;
    font-family: Brandon_blk_1;
    font-size: 25px;
  }
  .blog-content {
    margin: auto;
    display: grid;
    grid-template-columns: repeat(4,320px);
    grid-template-rows: 320px 320px;
    grid-gap: 10px;
    grid-row-gap: 10px;
    @media all and(max-width: 1350px) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 320px 320px;
      grid-gap: 20px;
      grid-row-gap: 20px;
    }
    @media all and(max-width: 1050px) {
      grid-template-columns: 1fr 1fr ;
      grid-template-rows: 320px 320px;
      grid-gap: 30px;
    }
    @media all and(max-width: 700px) {
      grid-template-columns: 1fr;
    }

    .blog-item {
      background-color: rgba(0, 0, 0, 0.8);
      overflow: hidden;
      position: relative;
      height: 320px;
      width: 320px;
      margin: auto;
      .blur {
        top: 0px;
        left: 0px;
        height: 320px;
        width: 320px;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.4);
      }
      .blog-title-a {
        position: relative;
        top: 10px;
        left: 20px;
        color: white;
        font-family: AlternateGotNo1D;
        font-size: 24px;
        text-transform: uppercase;
      }
      .blog-texto-a {
        position: relative;
        top: 110px;
        left: 20px;
        color: white;
        font-family: Brandon_blk_1;
        font-size: 21px;
        overflow-wrap: break-word;
        width: 200px;
        text-transform: uppercase;
      }
      .blog-cool-line#cool-line {
        position: absolute;
        border-bottom: solid 4px;
        width: 40px;
        bottom: 20px;
        left: 20px;
      }
    }
    .blog-item-hover {
      background-color: rgba(97, 83, 3, 0.7);
      color: white;
      position: absolute;
      height: 320px;
      width: 100%;
      top: 0px;
      left: 0px; // z-index: 5;
      display: none;
      padding-left: 20px;
      padding-top: 20px;
      .blog-cool-text-hover {
        padding-top: 30px;
        font-family: Brandon_med_1;
        width: 90%;
        font-size: 26px;
        div a{
          position: absolute;
          font-size: 20px;
          background-color: rgba(0, 0, 0,0.4);
          border-radius: 10%;
          padding: 3px 8px;
          bottom:20px;
          left:20px;
        }
        div a:hover {
          @include sombra(0 0 3px white);
        }
      }
      .blog-cool-line-hover {
        position: relative;
        border-bottom: solid 4px white;
        width: 40px;
      }
      .blog-hover-title {
        color: white;
        font-family: AlternateGotNo1D;
        font-size: 24px;
        text-transform: uppercase;
      }
      .blog-hover-text {
        color: white;
        font-family: Brandon_blk_1;
        font-size: 21px;
        overflow-wrap: break-word;
        width: 200px;
        text-transform: uppercase;
      }
      div {
        a {
          font-family: Brandon_blk_1;
          color: white;
        }
      }
    }
    .blog-item:hover {
      .blog-item-hover {
        display: initial;
        transition: 1200ms;
      }
      .blog-title-a {
        display: none;
      }
      .blog-texto-a {
        display: none;
      }
      .blog-cool-line {
        display: none;
      }
    }}
  }
}
