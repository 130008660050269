home-libro {
  background-image: url("../../assets/images/pabloiachetti_triangulos.png");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 30%;
  position: relative;
  background-color: white;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 35px 100px;
  width: 100%;
  height: auto;
  @media all and (max-width: $btLG) {
    padding: 15px;
    flex-direction: column;
  }
  .video {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
    padding-right: 25px;
    @media all and (max-width: $btLG) {
      padding: 0;
      margin-bottom: 30px;
      width: 100%;
    }
    video {
      width: 100%;
      margin-bottom: 20px;
    }
    .sale-section-title {
      font-family: Brandon_blk_1;
      font-size: 260%;
      @media all and (max-width: $btLG) {
        font-size: 145%;
      }
    }
    .horizontal-rule {
      color: rgba(0, 0, 0, 0);
      height: 1px;
      width: 100%;
      border-bottom: solid 1px black;
      margin-bottom: 26px;
    }
    .sale-section-description {
      width: 100%;
      font-family: TitilliumWeb-Light;
    }
  }
  .sale-section-box {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 25px;
    @media all and (max-width: $btLG) {
      padding: 0;
      width: 100%;
    }
    .no-full-width-please {
      width: 100%;
      h3 {
        font-family: Brandon_blk_1;
        text-transform: uppercase;
        font-size: 250%;
        @media all and (max-width: $btLG) {
          font-size: 130%;
        }
      }
      form.sale-form {
        width: 100%;
        .fila {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          .form-field {
            width: 100%;
            &:not(:last-of-type) {
              margin-right: 15px;
            }
            input {
              width: 100%;
            }
          }
        }
        .form-field {
          overflow: auto;
          display: flex;
          flex-direction: column;
          label {
            font-family: TitilliumWeb-Light;
          }
          input {
            width: 100%;
            padding: 5px 10px;
            border: none;
            border-bottom: 1px solid #333;
            background-color: #efefef;
            margin-bottom: 10px;
            font-family: TitilliumWeb-Light;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-align: left;
            &.ng-dirty.ng-valid {
              @include sombra(0 0 0 0 #000);
            }
            &:not(.ng-untouched) {
              &.ng-invalid-required,
              &.ng-dirty.ng-invalid,
              &.ng-dirty.ngInvalid {
                border-bottom: 1px solid #e33;
              }
            }
            &::placeholder {
              font-family: TitilliumWeb-Light;
              font-size: 90%;
            }

            &:-ms-input-placeholder {
              font-family: TitilliumWeb-Light;
              font-size: 90%;
            }

            &::-ms-input-placeholder {
              font-family: TitilliumWeb-Light;
              font-size: 90%;
            }
          }
          .codigo-descuento-field {
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            input {
              &.invalid-code {
                border-bottom: 1px solid #f00;
              }
            }
            i {
              position: absolute;
              right: 10px;
              margin-bottom: 15px;
            }
            small{
              color: #f66;
              margin-top: -10px;
            }
          }
        }
        .botones {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin-top: 15px;
          .boton {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            text-align: center;
            a {
              color: white;
              border: none;
              font-family: AlternateGotNo1D;
              font-size: 130%;
              text-transform: uppercase;
              text-align: center;
              width: 60%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 5px;
              padding-bottom: 2px;
              white-space: pre-line;
              text-decoration: none;
              border-radius: 4px;
              @include ease-transition;
              @include not-selectable;
              background-color: #ccc;
              cursor: not-allowed;
              &:not(.disabled) {
                background-color: #14aecf;
                cursor: pointer;
                &:hover {
                  background-color: #028dac;
                }
              }
              @media all and (max-width: $btLG) {
                width: 90%;
              }
            }
            small {
              font-family: TitilliumWeb-Light;
            }
          }
        }
        .respuesta {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin-top: 15px;
          text-align: center;
          span {
            font-family: TitilliumWeb-Light;
          }
        }
      }
    }
  }
}
