@import "../config";

body.page-videos {

  .big-image{
  
    width: 100%;
    min-height: 440px;
    position: relative;

    .texto-sobre-imagen-pagina-video {
      font-family: ArcaMajora3-Bold;
      font-weight: bolder;
      position: absolute;
      font-size: 134px;
      top: 140px;
      left: 28%;
      letter-spacing: 20px;
      font-weight: 40px;
      color:#fff;
      @media all and(max-width: 992px) {
        font-size: 60px;
        left: 20%;
      }
    }

    .texto-sobre-imagen-pagina-video-lateral {
      font-family: ArcaMajora3-Bold;
      position: absolute;
      transform: rotate(270deg);
      top: 220px;
      left: 23vw;
      font-size: 13px;
      color:#fff;
      @media all and(max-width: 992px) {
        transform: none;
      }
    }
  }

  .videos-content {
    min-height: 980px;
    margin-top: 50px;

    .video-title {
      font-family: TitilliumWeb-light;
    }

    .video-thumbnail {
      height: 400px;

    }

    .video-grid {
      display: grid;
      grid-template-columns: auto auto;
      grid-template-rows: auto auto;
      grid-gap: 100px;

      @media all and(max-width: 992px) {
        grid-template-columns: auto;
        width: 80%;
        margin: auto;
      }
    }

    .video-grid-item img,
    iframe {
      border-top: solid 1px #5E1A6B;
    }

    .video-grid-item,
    .video-grid-item img,
    iframe {
      width: 100%;
      height: 100%;

    }

    .video-grid-item:nth-child(odd) {

      margin-left: auto;

      .video-title {
        span {
          float: right;
          border-bottom: solid 4px #5E1A6B;
        }

        text-align: right;
        font-size: 20px;

      }
    }

    .video-grid-item:nth-child(even) {
      margin-right: auto;

      .video-title {
        font-size: 20px;

        span {
          float: left;
          border-bottom: solid 4px #5E1A6B;
        }

        text-align: left;

      }
    }
  }

  .paginado {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;

    .flechas {
      width: 30%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .flecha {
        @include minmaxwh(30px);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #5E1A6B;
        color: #fff;
        border-radius: 50%;
        @include ease-transition;
        cursor: pointer;

        &:hover {
          color: #5E1A6B;
          background-color: #fff;
          @include sombra(0 2px 0 0 #aaa);
        }
      }
    }
  }
}