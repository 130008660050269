@import "../config";
body.page-blog,body[class^="page-blog-tags"] ,body[class^="page-blog-categoria"]{

  .categoria-titulo {
    width: 100%;
    font-size: 20px;
    margin: 30px 0;
    font-family: Brandon_blk_1;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
  }
  
  .big-image{
    width: 100%;
    position: relative;
    min-height: 440px;
    color: white;
    img{
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .texto-sobre-imagen-blog {
      font-family: ArcaMajora3-Bold;
      font-weight: bolder;
      position: absolute;
      font-size: 120px;
      top: 150px;
      left: 36%;
      letter-spacing: 20px;
      @media all and(max-width: 992px) {
        font-size: 60px;
        top: 100px;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
    .texto-sobre-imagen-blog-lateral {
      font-family: ArcaMajora3-Bold;
      position: absolute;
      transform: rotate(270deg);
      top: 225px;
      left: 32vw;
      font-size: 11px;
      @media all and(max-width: 992px) {
        font-size: 20px;
        transform: none;
      }
    }
  }
  
  @import "content";
}

body[class^="page-blog-tags"] {

}
body[class^="page-blog-"] {
  .big-image{
    width: 100%;
    position: relative;
    min-height: 440px;
    color: white;
    img{
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .texto-sobre-imagen-blog {
      font-family: ArcaMajora3-Bold;
      font-weight: bolder;
      position: absolute;
      font-size: 120px;
      top: 150px;
      left: 36%;
      letter-spacing: 20px;
      @media all and(max-width: 992px) {
        font-size: 60px;
        top: 100px;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
    .texto-sobre-imagen-blog-lateral {
      font-family: ArcaMajora3-Bold;
      position: absolute;
      transform: rotate(270deg);
      top: 225px;
      left: 32vw;
      font-size: 11px;
      @media all and(max-width: 992px) {
        font-size: 20px;
        transform: none;
      }
    }
  }
  .volver-cnt {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 60px 20px 20px 60px;
    div{
      font-family: Brandon_blk_1;
      text-transform: uppercase;
    }
  }
  a.volver {
    border: none;
    letter-spacing: 3px;
    font-family: AlternateGotNo1D;
    background-color: #525254;
    color: white;
    padding: 3px 8px;
    margin: 0 20px 0 20px;
  }
  .blog-show-content {
    margin-left: 250px;
    margin-right: 250px; // margin-top: 120px;
    min-height: 2000px;
    padding-bottom: 300px;
    @media all and(max-width: 992px) {
      margin: 23px;
      padding: 0px;
    }
    .imagen-del-autor-elemento {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin: 25px 5px;
      img.imagen-del-autor-elemento {
        width: 100px;
        height: 100px;
      }
    }
    hr {
      border-top: 1px solid #ccc;
    }
    .categoria {
      font-family: 'Times New Roman', Times, serif;
      font-style: oblique;
      font-size: 20px;
      &:hover {
        @include sombra(0 1px 0 #ccc);
      }
    }
    .separador-linea-corta {
      margin-top: 10px;
      border-bottom-style: solid;
      border-bottom-width: 3px;
      width: 30px;
    }
    .primera-parte-del-blog {
      margin-top: 50px; // margin-bottom: 50px;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      .texto-contenedor {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: auto;
        .titulo-entrada-de-blog {
          font-family: Brandon_blk_1;
          font-size: 32px;
          text-transform: uppercase; // margin-bottom: 30px;
        }
        .subtitulo-entrada-de-blog {
          font-family: TitilliumWeb-light;
          overflow-wrap: break-word;
          color: #5E1A6B;
          font-size: 22px;
          font-style: oblique;
        }
        .fecha-de-creacion-entrada-de-blog {
          font-style: oblique;
          font-family: timesi;
        }
      }
    }
    .compartir-en-redes-sociales {
      position: absolute;
      display: flex;
      top: 540px;
      right: 100px;
      &#sector-comentarios {
        position: relative;
        margin: 10px 5px;
        right: 0;
        top: 0;
        left: 20px;
        .crs-facebook,.crs-twitter {
          margin:15px;
        }
      }
      span {
        font-family: Roboto-Regular;
      }
      .crs-facebook {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Roboto-Regular;
        border: white solid 1px; // padding: 0px 10px;
        border-radius: 50%;
        background-color: #3b5998;
        color: white;
        @include minmaxwh(30px);
        @include ease-transition();
        margin:15px;
        &:hover {
          @include sombra(2px 2px 2px #aaa);
        }
      }
      .crs-twitter {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Roboto-Regular;
        border: white solid 1px;
        margin-left: 4px; // padding: 0px 10px;
        border-radius: 50%;
        background-color: #00aced;
        color: white;
        @include minmaxwh(30px);
        @include ease-transition();
        margin:15px;
        &:hover {
          @include sombra(2px 2px 2px #aaa);
        }
      }
      .crs-share {
        font-family: Roboto-Regular;
        border: white solid 1px;
        margin-left: 2px;
        padding: 0px 10px;
        border-radius: 10px;
        background-color: #3b5998;
        color: white;
        @include ease-transition();
        &:hover {
          @include sombra(2px 2px 2px black);
        }
      }
    }
    .video-o-imagen-entrada-blog {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 866px;
      height: 400px;
    }
    p {
      margin-top: 24px;
      font-family: TitilliumWeb-Light;
      font-size: 20px;
    }
    iframe {
      background-color: #ddd;
      width: 100%;
      height: 400px;
    }
    .bajo-blog-post {
      display: flex;
      width: 100%;
      justify-content: space-between;
      @media all and(max-width: 992px) {
        flex-direction: column;
      }
      .fecha-de-creacion-entrada-de-blog {
        font-family: TitilliumWeb-Light;
      }
      .lista-de-tags {
        display: flex;
        margin-left: 15px;
      }
      .tags-de-blog {
        font-family: AlternateGotNo1D;
        font-size: 20px;
        font-style: oblique;
        margin: 0 20px 20px 20px;
        a {
          color: #5E1A6B;
          &:hover {
            border-bottom: 1px solid #5e1a6b;
          }
        }
      }
    }
    .caja-comentario-nuevo {
      position: relative;
      height: 300px;
      input {
        padding: 5px 10px;
        border: 1px solid #ccc;
      }
    }
    .seccion-categoria {
      font-family: timesi;
      font-size: 12px;
    }
    .seccion-tags {
      width: 70%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      
    }
    .presentacion-seccion-comentarios {
      position: absolute;
      top: 0px;
      left: 10px;
      font-family: AlternateGotNo1D;
      text-transform: uppercase;
      font-size: 28px;
      color: #5E1A6B;
    }
    img.profile-pic {
      top: 50px;
      position: absolute;
      height: 120px;
      width: 120px;
      margin-right: 10px;
    }
    #usuario-comentario {
      top: 40px;
      left: 150px;
      position: absolute;
      width: 600px;
      margin-bottom: 10px;
    }
    textarea.cuadro-para-comentario {
      top: 80px;
      left: 150px;
      position: absolute;
      resize: none;
      font-family: TitilliumWeb-Light;
      border-radius: 10px;
      width: 600px;
      height: 100px;
      clear: auto;
      padding: 5px 10px;
      border: 1px solid #ccc;
    }
    input.enviar-comentario[type=submit] {
      top: 200px;
      left: 150px;
      position: absolute;
      border: none;
      letter-spacing: 0.3vw;
      font-family: AlternateGotNo1D;
      background-color: #525254;
      color: white;
      padding: 5px 10px;
      cursor: pointer;
      &:disabled {
        background-color: #ddd;
      }
      &:hover:not(:disabled) {
        background-color: #6b6b6d;
      }
    }
    .no-comments {
      position: relative;
    }
    button.cargar-mas-comentarios {
      font-family: TitilliumWeb-Bold;
      width: 100%;
      border: none;
      color: #5E1A6B;
      cursor: pointer;
    }
    .lista-de-comentarios {
      position: relative;
      overflow-y: auto;
      max-height: 350px;
      .cada-comentario {
        margin-bottom: 18px;
        display: flex;
        align-items: flex-start;
        border-bottom: 1px solid #ccc;
        padding-bottom: 3px;
        .info-usuario {
          display: flex;
          flex-direction: column;
        }
        .imagen-de-perfil img {
          height: 120px;
          width: 120px;
          border-radius: 50%;
        }
        .nombre-de-usuario {
          font-family: TitilliumWeb-Bold;
        }
        .fecha-del-comentario {
          font-family: TitilliumWeb-Light;
          font-style: oblique;
        }
        .lugar-del-usuario {
          font-family: TitilliumWeb-Light;
        }
        .comentario {
          font-family: TitilliumWeb-Light;
          font-size: 24px;
          padding-left: 140px;
        }
      }
    }
  }
}