.contenedor-principal {
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  .menu-clear {
    width: 100%;
    display: block;
    position: relative;
    top: 0;
    left: 0;
    height: 150px;
    @media (max-width:$btLG){
      height: 50px;
    }
  }
}

@import "main-menu";
@import "footer-section";
@import "subscribe-newsletter";
@import "subscribe-events";